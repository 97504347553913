export const sendTokenMessageToNetlify = (messageToSend, token, provider) => {
  function receiveMessage(e) {
    console.log(`receiveMessage ${e}`);
    window.opener.postMessage(
      `authorization:${provider}:${messageToSend}:${JSON.stringify({
        token,
        provider,
      })}`,
      e.origin
    );
    window.removeEventListener("message", receiveMessage, false);
  }

  window.addEventListener("message", receiveMessage, false);
  console.log(`Sending message: ${provider}`);
  window.opener.postMessage(`authorizing:${provider}`, "*");
};
