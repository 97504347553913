// import { ThemeProvider } from "@mui/material/styles";
// import theme from "./util/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./styles/createEmotionCache";
import { ApolloProvider } from "@apollo/client";

import { client } from "./util/apollo";
import { UserProvider } from "./providers/UserProvider";

import { LoginScreen } from "./components/LoginScreen";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  return (
    <ApolloProvider client={client}>
      <CacheProvider value={emotionCache}>
        {/* <ThemeProvider theme={theme}> */}
        <CssBaseline />
        <UserProvider>
          <LoginScreen />
        </UserProvider>
        {/* </ThemeProvider> */}
      </CacheProvider>
    </ApolloProvider>
  );
}

export default App;
