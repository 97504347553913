import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { firebaseInstance } from "../../src/util/firebase";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const currentUser = firebaseInstance.auth().currentUser;

  return currentUser
    ? currentUser.getIdToken().then((token) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }))
    : { headers };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
