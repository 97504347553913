import { gql, useQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";

// const mockUserInput = {
//   id: "0f7b2b20-cfb3-11eb-9f2a-f7e231ef1123",
//   username: "testuser",
//   firstName: "Test",
//   lastName: "User",
// };

export const useGetCurrentUserQuery = (setCurrentUserCallback) => {
  const { data, loading, error } = useQuery(
    gql`
      query GetCurrentUser {
        getCurrentUser {
          id
          email
          photoURL
          username
          firstName
          lastName
          favoriteLinks
          communities
          updated
        }
      }
    `
  );

  useEffect(() => {
    !loading &&
      !error &&
      data.getCurrentUser.id &&
      setCurrentUserCallback(data.getCurrentUser);
  }, [data, loading, error]);
};

export const useAddOrUpdateUserFromFirebaseMutation = (
  setCurrentUserCallback
) => {
  const [updateUser, { data, loading, error }] = useMutation(
    gql`
      mutation addOrUpdateUserFromFirebase($firebaseUser: UserInput!) {
        addOrUpdateUserFromFirebase(firebaseUser: $firebaseUser) {
          id
          email
          photoURL
          username
          firstName
          lastName
          favoriteLinks
          communities
          updated
        }
      }
    `
    // {
    //   variables: { firebaseUser: mockUserInput },
    // }
  );

  useEffect(() => {
    if (error) {
      throw new Error(
        `Error in UserProvider mutation: ${error.message} ${error.extraInfo}`
      );
    } else if (!loading && data && data.addOrUpdateUserFromFirebase.id) {
      setCurrentUserCallback(data.addOrUpdateUserFromFirebase);
    }
  }, [data, loading, error]);

  return updateUser;
};
